import { Injectable, signal } from '@angular/core';
import { OperatorFunction, tap } from 'rxjs';

export function setLoaderFalse<T>(
  loader: LoaderService
): OperatorFunction<T, T> {
  return tap({
    next: () => loader.setLoader(false),
    error: () => loader.setLoader(false),
  });
}

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _loading = signal(false);

  get isLoading(): boolean {
    return this._loading();
  }

  setLoader = (isLoading: boolean) => this._loading.set(isLoading);
}
